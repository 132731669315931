import { Injectable } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'shared/core/services/user';

// App Access Activities
export enum Activity {
  PlatformAccess = 'Platform Access',
  ChatbotsAccess = 'Chatbots Access',
  RequestsAccess = 'Requests Access',
  MonitoringAccess = 'Monitoring Access',
  MonitoringUnlimitedAccess = 'Monitoring Unlimited access',
  Monitoring2YearsAccess = 'Monitoring 2 years access'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

  public static getUserActivities(user: User): Activity[] {
    if (!user) {
      return [];
    }
    return [].concat(...user.activities.map(gr => gr));
  }

  public static getUserPermissions(user: User): Map<Activity, boolean> {
    const activities = AuthorizationService.getUserActivities(user) || [];
    return AuthorizationService.buildPermissionsFromActivities(activities);
  }

  private static buildPermissionsFromActivities(activities = []) {
    const permissions = new Map<Activity, boolean>();
    for (const activity in Activity) {
      if (!Number(activity)) {
        permissions.set(Activity[activity], activities ? activities.some(a => a === Activity[activity]) : false);
      }
    }
    return permissions;
  }

  constructor(
    private keycloakService: KeycloakService,
    private localStorage: LocalStorageService
  ) {}

  public get currentUserPermissions() {
    return this.currentUser.pipe(map(user => AuthorizationService.getUserPermissions(user)));
  }

  public logout() {
    this.localStorage.store('kc-logout', 'true');
    setTimeout(() => this.localStorage.clear('kc-logout'));
    this.keycloakService.logout();
  }

  public isAllowed(activity: Activity | Activity[] = []): Observable<boolean> {
    const activities = Array.isArray(activity) ? activity : [activity];
    return this.currentUserPermissions.pipe(
      map(permissions => (activities && activities.length > 0 ? activities.some(a => permissions.get(a)) : true))
    );
  }
}
